<template>
  <div v-loading="loading" class="preview">
    <div v-if="instanceData.instanceId" class="content-outer">
      <div class="title">
        <div v-if="instanceData.staterUser">
          <avatar
            showY
            :name="instanceData.staterUser.name"
            :src="instanceData.staterUser.avatar"
          />
        </div>
        <div class="title-info">
          <div class="name">
            <span style="margin-right: 15px; color: #000">{{
              instanceData.processDefName
            }}</span>
            <el-tag :type="status.type" size="mini">{{ status.text }}</el-tag>
          </div>
          <div class="code">
            <span style="color: #6c6c6c">编号：</span>
            <span class="id">{{ instanceData.instanceId }}</span>
          </div>
          <img class="resul-img" v-if="status.img" :src="status.img" />
          <!--          <div style="font-size: 13px; color: rgb(142 141 141)" v-if="instanceData.staterUser">
                      由 {{instanceData.staterUser.name}} 在{{instanceData.startTime}}发起
                    </div>-->
        </div>
        <div class="extend-options" v-if="!isMobile">
          <el-tooltip
            class="item"
            effect="dark"
            content="打印"
            placement="top-start"
          >
            <i class="el-icon-printer" @click="print"></i>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="手机扫码"
            placement="top-start"
          >
            <i class="iconfont icon-iconfonterweima" @click="scanQr"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="tabs-outer">
        <van-tabs v-model="activeName">
          <van-tab title="基本信息" name="a"></van-tab>
          <van-tab
            title="业务明细"
            name="b"
            v-if="
              instanceData.businessData &&
              instanceData.businessData.hasFieldlist == '1'
            "
          ></van-tab>
          <van-tab title="流转记录" name="c"></van-tab>
          <van-tab title="流程图" name="d"></van-tab>
        </van-tabs>
      </div>

      <div class="form" v-if="activeName == 'a'">
        <form-render
          class="process-form"
          :mode="isMobile ? 'MOBILE' : 'PC'"
          ref="form"
          :forms="instanceData.formItems"
          :config="instanceData.formConfig"
          v-model="instanceData.formData"
          :instance-data="instanceData"
          :resultTableList="instanceData.result"
          @data-ready="handleDataReady"
        />
      </div>

      <!-- 调拨单明细 -->
      <template v-else-if="activeName == 'b'">
        <div
          class="InfoList"
          v-if="
            instanceData.businessData &&
            instanceData.businessData.hasFieldlist == '1'
          "
        >
          <div class="block-title">
            <span>业务明细</span>
          </div>
          <!-- v-for="(item, index) in instanceData.formData.fieldlist[0]
              .carListbyUser" -->
          <!-- 卡的明细 -->
          <div
            v-if="instanceData.modelNo == '7' || instanceData.modelNo == '8'"
          >
            <template v-for="(item, index) in instanceData.formData.fieldlistM">
              <div class="tem" :key="index" @click="goDetailtem(item, index)">
                <div class="info-title">
                  <span>{{ item.cardNumber }}</span>
                </div>
                <div class="info-title">
                  <span>{{ item.brandName }}</span>
                </div>

                <div class="info-line">
                  <div class="info-line info-line-l">
                    <span class="t">卡介质：</span>
                    <span>{{
                      item.attributeType == 1
                        ? "实体卡"
                        : item.attributeType == 2
                        ? "电子卡"
                        : item.attributeType
                    }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">卡属性：</span>
                    <span
                      ><span>{{
                        item.cardType == 0
                          ? "储值卡"
                          : item.cardType == 1
                          ? "次卡"
                          : item.cardType == 2
                          ? "期限卡"
                          : item.cardType
                      }}</span></span
                    >
                  </div>
                </div>
                <div class="info-line">
                  <div class="info-line info-line-l">
                    <span class="t">会员姓名：</span>
                    <span>{{ item.userName }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">会员手机号：</span>
                    <span
                      ><span>{{ item.userPhone }}</span></span
                    >
                  </div>
                </div>
                <!-- 卡延期 -->
                <template v-if="instanceData.modelNo == '7'">
                  <div class="info-line">
                    <div class="info-line info-line-l">
                      <span class="t">原有效期：</span>
                      <span>{{ item.endTime }}</span>
                    </div>
                    <div class="info-line info-line-r">
                      <span class="t">延期天数：</span>
                      <span
                        ><span>{{ item.days }}</span></span
                      >
                    </div>
                  </div>
                  <div class="info-line">
                    <div class="info-line info-line-l">
                      <span class="t">延期至：</span>
                      <span>{{ item.optTime }}</span>
                    </div>
                  </div>
                </template>
                <!-- 卡停卡 -->
                <template v-if="instanceData.modelNo == '8'">
                  <div class="info-line">
                    <div class="info-line info-line-l">
                      <span class="t">停卡开始：</span>
                      <span>{{ item.startTime }}</span>
                    </div>
                    <div class="info-line info-line-r">
                      <span class="t">停卡结束：</span>
                      <span
                        ><span>{{ item.endTime }}</span></span
                      >
                    </div>
                  </div>
                  <div class="info-line">
                    <div class="info-line info-line-l">
                      <span class="t">原有效期：</span>
                      <span>{{ item.cardEndTime }}</span>
                    </div>
                    <div class="info-line info-line-r">
                      <span class="t">合计天数：</span>
                      <span
                        ><span>{{ item.days }}</span></span
                      >
                    </div>
                  </div>
                  <div class="info-line">
                    <div class="info-line info-line-l">
                      <span class="t">延期至：</span>
                      <span>{{ item.cardEndTimeTo }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <!-- 券的明细 -->
          <div v-else>
            <template v-for="(item, index) in instanceData.formData.fieldlistM">
              <div class="tem" :key="index" @click="goDetailtem(item, index)">
                <div class="info-title">
                  <!-- {{ instanceData.modelNo }} -->
                  <span>{{ item.brandName }}</span>
                  <!-- {{ item.brandType }} -->
                  <img
                    src="../../../assets/image/dtj.png"
                    class="img"
                    v-if="item.brandType == '2'"
                  />
                  <img
                    src="../../../assets/image/zu1.png"
                    class="img"
                    v-if="item.brandType == '3'"
                  />
                </div>
                <div class="tickt-name" v-if="instanceData.modelNo == '5'">
                  <div class="info-title">
                    <!-- item.brandType == "3" ? item.brandBn : item.goodsBn  -->
                    <span>{{ item.goodsBn }}</span>
                  </div>
                </div>
                <div class="info-line">
                  <div class="info-line info-line-l">
                    <span class="t">券介质：</span>
                    <span>{{
                      item.attributeType == "1" ? "纸质券" : "电子券"
                    }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">申请数量：</span>
                    <span>{{ item.shenqingCount }}</span>
                  </div>
                </div>
                <!-- 销售礼券申请显示面额、单价 -->
                <div class="info-line" v-if="instanceData.modelNo == '2'">
                  <div class="info-line info-line-l">
                    <span class="t">面额：</span>
                    <span>{{ item.brandPrice }} 元</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">单价：</span>
                    <span>{{ item.sellPrice }} 元</span>
                  </div>
                </div>
                <!-- 销售礼券以及免费礼券-->
                <div
                  class="info-line"
                  v-if="
                    instanceData.modelNo == '2' || instanceData.modelNo == '3'
                  "
                >
                  <div class="info-line info-line-l">
                    <span class="t">客户姓名：</span>
                    <span>{{ item.userName }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">手机号：</span>
                    <span>{{ item.userPhone }}</span>
                  </div>
                </div>
                <!-- 折扣申请 -->
                <div class="info-line" v-if="instanceData.modelNo == '4'">
                  <div class="info-line info-line-l">
                    <span class="t">面额：</span>
                    <span>{{ item.brandPrice }} 元</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">申请折扣：</span>
                    <span>{{ item.discount }} %</span>
                  </div>
                </div>
                <div class="info-line" v-if="instanceData.modelNo == '4'">
                  <div class="info-line info-line-l">
                    <span class="t">申请单价：</span>
                    <span>{{ item.discountedPrice }}元</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">申请总价：</span>
                    <span>{{ item.discountedTotalPrice }}元</span>
                  </div>
                </div>
                <!--折扣申请 -->
                <div class="info-line" v-if="instanceData.modelNo == '5'">
                  <div class="info-line info-line-l">
                    <span class="t">券状态：</span>
                    <span>{{ item.status | typeFilter }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">券用途：</span>
                    <span>{{
                      item.useType == "1"
                        ? "销售"
                        : item.useType == "2"
                        ? "免费"
                        : "工具"
                    }}</span>
                  </div>
                </div>
                <div class="info-line" v-if="instanceData.modelNo == '5'">
                  <div class="info-line info-line-l">
                    <span class="t">有效期：</span>
                    <span>{{ item.endTime }}</span>
                  </div>
                  <div class="info-line info-line-r">
                    <span class="t">延期至：</span>
                    <span>{{ item.optTime }}</span>
                  </div>
                </div>
                <div
                  v-if="perm == 'E'"
                  style="display: flex; justify-content: right"
                >
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteItem(item, index, $event)"
                    >删除</el-button
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div class="process" v-else-if="activeName == 'c'">
        <process-progress
          :rows="instanceData"
          :result="instanceData.result"
          :status="instanceData.status"
          :progress="instanceData.progress"
        />
      </div>
      <div class="process" v-else-if="activeName == 'd'">
        <process-design
          ref="processDesign"
          :group-id="instanceData.groupId"
          :code="instanceData.formId"
          :version="instanceData.version.toString()"
          :instance-data="instanceData"
        />
      </div>
      <div class="actions" v-if="instanceData.result === 'RUNNING'">
        <div style="position: relative; width: 100%; height: 100%">
          <!-- <div class="comment" @click="comment">
            <i class="el-icon-chat-line-round"></i>
            <div>评论</div>
          </div> -->
          <!--          <div class="comment comment-group" @click="commentGroup">
            <i class="iconfont icon-huiyi"></i>
            <div>讨论组</div>
          </div>-->
          <template v-if="instanceData.operationPerm">
            <div v-if="activeTasks.length > 0 && showMore" class="action-more">
              <el-dropdown>
                <el-button type="text">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="opPerms.transfer.show"
                    @click.native="handler('transfer')"
                  >
                    <i class="iconfont icon-zhuanyi-16"></i>
                    {{ opPerms.transfer.alisa }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="opPerms.recall.show"
                    @click.native="handler('recall')"
                  >
                    <i class="el-icon-s-release"></i>
                    {{ opPerms.recall.alisa }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="opPerms.afterAdd.show"
                    @click.native="handler('afterAdd')"
                  >
                    <i class="iconfont icon-zhaopinguanli"></i>
                    {{ opPerms.afterAdd.alisa }}
                  </el-dropdown-item>
                  <!-- <el-dropdown-item
                    v-if="enableCancel"
                    @click.native="handler('cancel')"
                  >
                    <i class="el-icon-refresh-left"></i>
                    撤销
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-if="activeTasks.length > 0" class="ok-refuse">
              <el-button
                size="mini"
                v-if="opPerms.refuse.show"
                type="danger"
                round
                plain
                @click="handler('refuse')"
                >{{ opPerms.refuse.alisa }}</el-button
              >
              <el-button
                size="mini"
                v-if="opPerms.agree.show"
                type="primary"
                round
                @click="handler('agree')"
                >{{ opPerms.agree.alisa }}</el-button
              >
            </div>
            <div
              class="take"
              v-if="
                activeTasks.length == 0 &&
                instanceData.progress[instanceData.progress.length - 1]
                  .nodeType == 'CHECK' &&
                (instanceData.progress[instanceData.progress.length - 1]
                  .candidateUser ||
                  instanceData.progress[instanceData.progress.length - 1].user
                    .id == null)
              "
            >
              <el-button round type="primary" @click="takeOrder">
                接单
              </el-button>
            </div>
          </template>
          <div class="cancel" v-else-if="enableCancel">
            <el-button type="text" @click="handler('cancel')">
              <i class="el-icon-refresh-left"></i>
              撤销
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <w-dialog
      v-model="printVisible"
      width="700px"
      title="打印预览"
      okText="打 印"
      @ok="doPrint"
    >
      <div slot="title">
        <span>打印预览</span>
        <el-radio-group
          style="margin: 0 30px"
          v-model="printCheck"
          @change="renderPrint"
          v-if="printTemplateConfig.customPrint"
        >
          <el-radio :label="false">默认模板</el-radio>
          <el-radio :label="true">自定义模板</el-radio>
        </el-radio-group>
      </div>
      <div
        v-if="printCheck"
        id="printDom"
        ref="print"
        v-html="printTemplateConfig.printTemplate"
      ></div>
      <default-printer
        v-else
        ref="print"
        :status="status"
        :instance="instanceData"
      />
    </w-dialog>
    <el-drawer
      size="100%"
      direction="rtl"
      title="券详情"
      style="z-index: 1"
      :visible.sync="tickDetailVisible"
    >
      <tickRequestDetails1
        :itemCurrentItemStr="itemCurrentItemStr"
        :instance-id="instanceData.instanceId"
        :task-id="activeTasks"
        :form-data="formData"
        @close-drawer="handleCloseDrawer"
        ref="detailRef"
      ></tickRequestDetails1>
    </el-drawer>
    <!-- <w-dialog
      size="100%"
      direction="rtl"
      title="券详情"
      v-model="tickDetailVisible"
    >
      <tickRequestDetails1
        :itemCurrentItemStr="itemCurrentItemStr"
      ></tickRequestDetails1>
    </w-dialog> -->
    <w-dialog
      v-model="actionVisible"
      :ok-loading="actionLoading"
      width="90vw"
      :title="actionDesc.title"
      :okText="actionType === 'appraise' ? '评 价' : '同 意'"
      @ok="doAction"
    >
      <process-action
        ref="action"
        @success="handlerOk"
        @fail="actionLoading = false"
        :form-data="formData"
        v-if="actionVisible"
        :active-tasks="activeTasks"
        :instance="instanceData"
        :action="actionType"
        :action-desc="actionDesc"
        :tklimit="tklimit"
        show-sign
      />
    </w-dialog>
    <!-- <van-number-keyboard safe-area-inset-bottom /> -->
  </div>
</template>

<script>
import FormRender from "@/views/common/form/FormRender";
import ProcessProgress from "./ProcessProgress";
import ProcessAction from "./ProcessAction";
import tickRequestDetails1 from "../tickRequestDetails1.vue";
import { getFormAndProcessProgress, approvalTask } from "@/api/processTask";
import { getCustomPrintConfig } from "@/api/process";
import moment from "moment";
import { Toast, Dialog } from "vant";
import Print, { bindVar, getVal } from "@/utils/print";
import DefaultPrinter from "./DefaultPrinter";
import ProcessDesign from "../../process/submit/FormProcessDesign2";

export default {
  name: "ProcessInstancePreview",
  components: {
    DefaultPrinter,
    FormRender,
    ProcessProgress,
    ProcessAction,
    tickRequestDetails1,
    ProcessDesign,
  },
  props: {
    instanceId: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      required: false,
    },
  },
  filters: {
    //// 1:在库，2:调拨，3:销售，4:发放，5:转赠，6:核销,7:强制核销，8:确认收入，9:作废
    typeFilter(mType) {
      if (mType == "1") {
        return "在库";
      } else if (mType == "2") {
        return "调拨";
      } else if (mType == "3") {
        return "销售";
      } else if (mType == "4") {
        return "发放";
      } else if (mType == "5") {
        return "转赠";
      } else if (mType == "6") {
        return "核销";
      } else if (mType == "7") {
        return "强制核销";
      } else if (mType == "8") {
        return "确认收入";
      } else if (mType == "9") {
        return "作废";
      } else {
        return "";
      }
    },
  },
  computed: {
    loginUser() {
      return this.$store.state.loginUser;
    },
    isMobile() {
      return window.screen.width < 450;
    },
    isRootUser() {
      return this.instanceData?.staterUser?.id === this.loginUser?.id;
    },
    enableCancel() {
      try {
        return this.instanceData.externSetting.enableCancel;
      } catch (e) {
        return false;
      }
    },
    showMore() {
      return (
        this.opPerms?.transfer?.show ||
        this.opPerms?.recall?.show ||
        this.opPerms?.afterAdd?.show ||
        this.enableCancel
      );
    },
    opPerms() {
      const opPerms = this.instanceData.operationPerm || {};
      for (let key in opPerms) {
        if (!opPerms[key]) {
          opPerms[key] = { alisa: "", show: false };
        }
      }
      return opPerms;
    },
    formatFormData() {
      let val = {};
      getVal(
        this.instanceData.formData || {},
        this.instanceData.formItems || [],
        val
      );
      val.ownerDept = this.instanceData.starterDept;
      val.owner = this.instanceData.staterUser.name;
      val.startTime = this.instanceData.startTime;
      val.finishTime = this.instanceData.finishTime;
      val.code = this.instanceData.instanceId;
      return val;
    },
    status() {
      if (
        this.instanceData.finishTime &&
        this.instanceData.result == "RUNNING" &&
        this.instanceData.sub
      ) {
        status = {
          type: "info",
          img: require("../../../assets/image/recall.png"),
          text: "主流程被撤销",
        };
        return status;
      }
      let status = {
        text: this.instanceData.status,
      };
      switch (this.instanceData.result) {
        case "RUNNING":
        case "COMPLETE":
          status.type = "";
          status.img = null;
          break;
        case "PASS":
          status.type = "success";
          status.img = require("../../../assets/image/agree.png");
          break;
        case "CANCEL":
          status.type = "info";
          status.img = require("../../../assets/image/recall.png");
          break;
        case "INVALID":
          status.type = "warning";
          status.img = require("../../../assets/image/invalid.png");
          break;
        case "ALLOCATE":
          status.type = "success";
          status.img = require("../../../assets/image/allocate.png");
          break;
        case "REFUSE":
          status.type = "danger";
          status.img = require("../../../assets/image/refuse.png");
          break;
      }
      return status;
    },
    activeTasks() {
      let tasks = [];
      (this.instanceData.progress || []).forEach((task) => {
        if (task.users) {
          task.users.forEach((tk) => {
            if (
              tk.user &&
              tk.user.id === this.loginUser.id &&
              !this.$isNotEmpty(tk.finishTime)
            ) {
              tasks.push(tk);
            }
          });
        } else {
          if (
            task.user &&
            task.user.id === this.loginUser.id &&
            !this.$isNotEmpty(task.finishTime)
          ) {
            tasks.push(task);
          }
        }
      });
      return tasks;
    },
    formData() {
      //过滤出可编辑的表单字段
      let formFields = [];
      let formData = {};
      this.getEnableEditForm(this.instanceData.formItems || [], formFields);
      formFields.forEach((k) => {
        this.$set(formData, k, this.instanceData.formData[k]);
      });
      return formData;
    },
    actionDesc() {
      switch (this.actionType) {
        case "submit":
          return { tip: "备注", title: "重新提交" };
        case "agree":
          return {
            tip: "审批意见",
            // tip: this.isRootUser ? "备注" : "审批意见",
            // title: this.isRootUser ? "提交审批" : "同意审批",
            title: "同意审批",
          };
        case "refuse":
          return { tip: "驳回意见", title: "拒绝审批" };
        case "comment":
          return { tip: "评论内容", title: "添加评论" };
        case "beforeAdd":
          return { tip: "加签意见", title: "前方增加审批人" };
        case "afterAdd":
          return { tip: "加签意见", title: "后方增加审批人" };
        case "transfer":
          return { tip: "转交意见", title: "转交给其他人审批" };
        case "cancel":
          return { tip: "撤销原因", title: "撤销当前流程" };
        case "recall":
          return { tip: "退回意见", title: "退回到之前节点" };
      }
    },
  },
  data() {
    return {
      perm: "R",
      tklimit: {},
      newDtail: "",
      activeName: "",
      tickDetailVisible: false,
      itemCurrentItemStr: "",
      printVisible: false,
      actionVisible: false,
      printCheck: false,
      loading: false,
      actionLoading: false,
      actionType: "agree",
      instanceData: {},
      agreeVisible: false,
      printTemplateConfig: {
        customPrint: false,
        printTemplate: "",
      },
    };
  },
  mounted() {
    this.getInstanceData();
    this.getPrintConfig();
  },
  // watch: {
  //   itemCurrentItemStr: {
  //     deep: true,
  //     handler(newData, old) {
  //       this.newDtail = newData;
  //       console.log("变化了" + newData.brandName + "```" + old.brandName);
  //     },
  //   },
  // },
  methods: {
    deleteItem(item, index, event) {
      // 阻止事件冒泡
      event.stopPropagation();
      Dialog.confirm({
        title: "提示",
        message: "是否确认删除？",
      }).then(() => {
        Object.keys(this.tklimit).length = 0
          ? thihs.tklimit.fieldlistM.splice(index, 1)
          : this.formData.fieldlistM
          ? this.formData.fieldlistM.splice(index, 1)
          : [];
      });
    },
    handleCloseDrawer(params) {
      this.tklimit = params;
      this.tickDetailVisible = false;
    },
    handleDataReady(data) {
      this.perm = data;
    },
    goDetailtem(itemString, index) {
      //tickRequestDetails
      //  let itemParam=JSON.parse(item)
      // let ParamObj={
      //    ...itemParam,
      //     groupIndex:groupIndex,
      //     subIndex:subIndex,
      //     carSelectType:this.selectType
      // }

      //console.log(itemString.isUpdateLimit + "``````````");

      // this.itemCurrentItemStr = "";
      // this.tickDetailVisible = false;
      let detailObj = {
        ...itemString,
        index: index,
        modelNo: this.instanceData.modelNo,
        isDfaultData: itemString.isUpdateLimit == "1" ? 0 : 1, //不用掉接口取详情数据了
        perm: this.perm,
      };

      this.itemCurrentItemStr = JSON.stringify(detailObj);
      // this.$refs.detailRef.opening(this.itemCurrentItemStr);
      this.tickDetailVisible = true;
      // this.$router.push({
      //   path: "/tickRequestDetails",
      //   query: {
      //     paramObj: itemString,
      //     isEdit: 0,
      //   },
      // });
    },
    //根据退回进行分段拆解
    splitByRecall(progress) {
      //提取分段时间点
      let points = progress
        .filter((p) => p.result === "recall")
        .map((p) => new Date(p.startTime).getTime());
      if (points.length > 0) {
        let blocks = []; //分段流程块组
        let pointer = 0; //定点索引
        points.push(new Date().getTime());
        points.forEach((point) => {
          let block = [];
          for (let i = pointer; i < progress.length; i++) {
            let startTime = new Date(progress[i].startTime).getTime();
            if (startTime <= point) {
              block.push(progress[i]);
            } else {
              pointer = i;
              break;
            }
          }
          //存段
          blocks.push(block);
        });
        //按段处理
        let processNodes = [];
        blocks.forEach((block) => processNodes.push(...this.mergeTask(block)));
        return processNodes;
      }
      return this.mergeTask(progress);
    },
    //合并活动节点，此处执行一段合并算法用来处理退回导致节点重合的问题
    mergeTask(progress) {
      let merge = [];
      progress.forEach((pg) => {
        //节点合并条件，满足以下条件就合并节点
        let i = merge.findIndex(n => (n.nodeId === pg.nodeId && pg.name !== '超时抄送') || (pg.name == '超时抄送' && n.name == '超时抄送' && n.nodeId == pg.nodeId))
        if (i > -1) {
          //存在则合并到对象
          if (merge[i].users) {
            //已经合并过了
            merge[i].finishTime = pg.finishTime;
            merge[i].users.push(pg);
            merge[i].result = this.getApprovalResult(
              merge[i],
              pg,
              pg.approvalMode
            );
            merge[i].comment = merge[i].comment.concat(pg.comment);
          } else {
            //没有就合并
            merge[i] = {
              agree: null,
              name: pg.name,
              nodeType: pg.nodeType,
              approvalMode: pg.approvalMode,
              nodeId: pg.nodeId,
              result: this.getApprovalResult(merge[i], pg, pg.approvalMode),
              startTime: merge[i].startTime,
              finishTime: pg.finishTime,
              comment: merge[i].comment.concat(pg.comment),
              users: [merge[i], pg],
            };
            merge[i].comment = merge[i].comment.sort(
              (a, b) => moment(a.createTime) - moment(b.createTime)
            );
          }
        } else {
          merge.push(pg);
        }
      });
      return merge;
    },
    getInstanceData() {
      this.loading = true;
      getFormAndProcessProgress(this.instanceId, this.nodeId)
        .then((rsp) => {
          this.loading = false;
          this.instanceData = rsp.data;
          // 处理一下progeress，因为候选人分批插入，如果时间不同可能会出现两条待办理数据。
          let count = 0;
          for (let i = 0; i < rsp.data.progress.length; i++) {
            if (
              !rsp.data.progress[i].user.id &&
              rsp.data.progress[i].user == null &&
              rsp.data.progress[i].nodeType == "CHECK"
            ) {
              count++;
            }
          }
          if (count > 0) {
            let found = false;
            rsp.data.progress = rsp.data.progress.filter((item) => {
              if (item.user.id === null && item.nodeType === "CHECK") {
                if (!found) {
                  found = true;
                  return true; // 保留第一个符合条件的项
                }
                return false; // 过滤掉其余符合条件的项
              }
              return true; // 保留其他项
            });
          }
          this.instanceData.progress = this.splitByRecall(rsp.data.progress);
        })
        .catch((err) => {
          this.loading = false;
          this.$err(err, "获取审批实例数据失败");
        });
    },
    getApprovalResult(oldVal, newVal, mode) {
      if (mode === "OR") {
        return newVal.result ? newVal.result : oldVal.result;
      } else if (mode === "AND") {
        let rs = oldVal.result || newVal.result;
        return rs === "recall" ? "recall" : rs;
      } else {
        return newVal.result;
      }
    },
    comment() {
      this.actionType = "comment";
      this.actionVisible = true;
    },
    handler(action) {
      this.actionType = action;
      this.actionVisible = true;
    },
    doAction() {
      this.activeName = "a";
      setTimeout(() => {
        if (
          this.actionType === "recall" ||
          this.actionType === "refuse" ||
          this.actionType === "agree"
        ) {
          // console.log(this.$refs.form + "``````````");
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.actionLoading = true;
              this.$refs.action.submitAction();
              this.actionLoading = false;
            } else {
              this.actionLoading = false;
              this.actionVisible = false;
              if (this.isMobile) {
                Toast.fail("请完善表单");
              } else {
                this.$message.warning("请完善表单");
              }
            }
          });
        } else {
          this.$refs.action.submitAction();
        }
      }, 500);
    },
    print() {
      this.printVisible = true;
    },
    doPrint() {
      Print(this.printCheck ? this.$refs.print : this.$refs.print.$el);
    },
    scanQr() {
      this.$message.warning("敬请期待");
    },
    handlerOk() {
      // console.log("处理---handlerOk");
      this.actionVisible = false;
      this.actionLoading = false;
      this.$emit("handler-after");
    },
    getEnableEditForm(forms, fields) {
      forms.forEach((f) => {
        if (f.name === "SpanLayout") {
          this.getEnableEditForm(f.props.items, fields);
        } else if (f.name === "TableList") {
          fields.push(f.id);
        } else if (f.perm === "E") {
          fields.push(f.id);
        }
      });
    },
    getPrintConfig() {
      getCustomPrintConfig(this.instanceId)
        .then((rsp) => {
          this.printTemplateConfig = rsp;
        })
        .catch((err) => {
          this.$err(err, "获取打印模板配置失败");
        });
    },
    renderPrint(val) {
      if (val) {
        this.$nextTick(() => {
          bindVar(
            this.printTemplateConfig.printTemplate,
            this.formatFormData,
            "printDom"
          );
        });
      }
    },
    takeOrder() {
      this.actionType = "take";
      let params = {
        instanceId: this.instanceId,
        formData: this.formData,
        action: this.actionType,
      };
      approvalTask(params)
        .then((rsp) => {
          this.$ok(rsp, "运维接单成功");
          this.$emit("handler-after");
        })
        .catch((err) => {
          this.$err(err, "运维接单失败");
        });
      return;
    },
  },
};
</script>
<style>
.v-modal {
  z-index: 1 !important;
}
</style>
<style lang="less" scoped>
.content-outer {
  width: 100vw;
}
.resul-img {
  position: absolute;
  top: 0 !important;
  right: 6rm !important;
  z-index: 10000;
}

/deep/ .el-tag--mini {
  height: 25px;
  line-height: 25px;
}
/deep/ .van-nav-bar__text {
  color: #006c50 !important;
}
/deep/ .van-nav-bar .van-icon {
  color: #006c50 !important;
}
/deep/ .van-checkbox__icon--checked .van-icon {
  color: #fff;
  background-color: #006c50 !important;
  border-color: #006c50 !important;
}

.tabs-outer {
  /deep/ .van-tab {
    font-size: 1.3rem;
  }
  /deep/ .van-tabs__line {
    background-color: #006c50;
  }
}
.InfoList {
  background-color: #fefefe;
  margin-bottom: 1rem;
  padding: 2rem 1rem;
  .block-title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .tem {
    margin-bottom: 0.7rem;
    border-radius: 1rem;
    padding: 1rem;

    box-shadow: 0px 0.2rem 1rem rgba(1, 12, 23, 0.06);
    .info-title {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: #333;
      margin-bottom: 1rem;
      .img {
        width: 4rem;
        margin-left: 1rem;
      }
    }
    .info-line {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.7rem;
      .info-line {
        color: #333;
        flex: 1;
        .t {
          color: #989595;
        }
      }
    }
  }
}
.preview {
  background: #fff;
  position: relative;
  height: 100%;
  width: 100%;

  & > div:nth-child(1) {
    overflow-y: auto;
    height: 100%;
    //padding-bottom: 50px;
  }

  .actions {
    height: 60px;
    padding: 25px 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    border-top: 1px solid #dcdfe6;
    box-shadow: 0 0 15px 0px #dad8d8;

    .ok-refuse,
    .take {
      position: absolute;
      right: 20px;
      top: -10px;
    }

    .action-more {
      position: absolute;
      right: 180px;
      top: -15px;
    }

    .cancel {
      position: absolute;
      right: 20px;
      top: -10px;
    }

    .comment-group {
      left: 70px !important;
    }

    .comment {
      position: absolute;
      left: 20px;
      top: -5px;
      cursor: pointer;
      text-align: center;
      font-size: 12px;

      i {
        font-size: 20px;
      }

      &:hover {
        color: @theme-primary;
      }
    }
  }
}

.process {
  background: white;
  padding: 1rem;
  padding-bottom: 60px;
}

.title {
  background: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 1rem 1rem 1rem;

  & > div {
    color: #666666;
    display: inline-block;
  }

  .title-info {
    height: 50px;
    display: flex;
    padding: 0 10px;
    flex: 1;

    position: relative;
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-size: 1.4rem;
      color: #5e5e5e;
    }

    .code {
      font-size: 1.3rem;
    }

    img {
      width: 6rem;

      position: absolute;
      right: 60px;
      top: 30px;
      z-index: 9999;
    }
  }

  .extend-options {
    position: absolute;
    right: 0;

    i {
      cursor: pointer;
      padding: 0 10px;

      &:hover {
        color: @theme-primary;
      }
    }
  }
}

.form {
  margin: 15px 0;
  padding: 1rem;
  background: white;
  padding-bottom: 60px;
}
</style>
